/* eslint-disable @typescript-eslint/no-unsafe-assignment */
/* eslint-disable camelcase */
import dutchMessages from 'ra-language-dutch'
import { StringMap } from 'react-admin'

export const i18nDutch: StringMap = {
  ...dutchMessages,
  dashboard: {
    applications: 'Applicaties',
    tickets: 'Meldingen',
    greeting: {
      morning: 'Goedemorgen',
      afternoon: 'Goedemiddag',
      evening: 'Goedenavond',
    },
    description: 'Welkom bij mijn.xxllnc, de MijnOmgeving van xxllnc waarin wij je de mogelijkheid ' +
    'geven om digitaal zaken met ons te doen. In het menu links kun je de opties terugvinden die beschikbaar zijn. '+
    'De gegevens zijn op basis van de organisatie waarvoor je werkt en de rol die je hebt.',
    descriptionAdmin: 'Dit is de admin omgeving van xxllnc.',
    allApps: 'Ontdek alle applicaties',
    allAppsDescription: 'Bekijk alle beschikbare xxllnc applicaties.',
  },
  resources: {
    organization: {
      name: 'Organisatie |||| Organisaties',
      title: 'Organisatie overzicht',
      list: 'Organisaties',
      create: 'Organisatie toevoegen',
      edit: '%{name} bewerken',
      import: 'Importeer organisaties',
      activate: 'Heractiveer inactieve organisatie',
    },
    app: {
      title: 'Applicaties',
      list: 'Applicaties',
      create: 'Applicatie toevoegen',
      edit: '%{name} bewerken',
      showModules: 'Toon modules',
      activate: 'Heractiveer inactieve applicatie',
    },
    app_overview: {
      name: 'Applicatie |||| Applicaties',
      title: 'Applicaties',
      welcome: 'Welkom terug %{name}',
      message:
        'Welkom in de xxllnc applicatie winkel. Hier vind je alle applicaties van ' +
        'jouw organisatie en kun je eenvoudig nieuwe applicaties toevoegen. ' +
        'Wist je dat je ook snel en eenvoudig een extra omgeving voor ' +
        'een bestaande applicatie kunt toevoegen. ' +
        'Klik op een applicatie naar keuze om te beginnen.',
      activatedApps: 'Geactiveerde applicaties',
      addEnvironment: 'Omgeving toevoegen',
      availibleApps: 'Beschikbare applicaties',
      demo: 'Aanvragen demo',
      demoUrl: 'Demo url',
      description: 'Omschrijving',
      domain: 'Domein',
      environments: 'Omgevingen',
      imageUrl: 'Afbeelding',
      information: 'Meer informatie',
      noApps: 'Er zijn geen applicaties die voldoen aan je zoekopdracht',
      noReviews: 'Er zijn nog geen reviews voor deze applicaties. Wees de eerste en deel jouw ervaringen.',
      published: 'Laatste update',
      reviews: 'Recenties',
      support: 'Ondersteuning',
      version: 'Huidige versie',
      writeReview: 'Schrijf recentie',
      inUse: 'In gebruik',
      requestable: 'Aanvragen',
      team: 'Team:',
      fairUse: 'Fair use policy:',
    },
    app_instance: {
      name: 'Omgeving |||| Omgevingen',
      title: 'Overzicht van de omgeving',
      list: 'Omgevingen',
      create: 'Omgeving aanmaken voor %{name}',
      edit: 'Omgeving voor %{name} aanpassen',
      hosts: 'Hosts',
      hostAlias: 'Hostalias',
      default: 'Default host alias',
      setDefault: 'Maak standaard',
      requestLabel: 'Omgeving aanvragen',
      requestText: 'Je kan er als organisatie voor kiezen om verschillende omgevingen te creëren ' +
        'voor een app, zodat alleen een bepaalde groep gebruikers bij projecten of bestanden van ' +
        'een bepaalde omgeving kan.',
      import: 'Importeer omgevingen',
      activate: 'Heractiveer inactieve omgeving',
    },
    app_instance_configuration: {
      title: 'Instellingen',
      create: 'Instelling aanmaken',
      edit: 'Instelling aanpassen',
    },
    app_integration: {
      title: 'Koppeling toevoegen',
      create: 'Koppeling toevoegen aan %{name}',
      edit: 'Koppeling aanpassen',
      empty: 'Nog geen koppelingen voor deze applicatie.',
      notAvailable: 'Er zijn nog geen koppelingen beschikbaar voor deze applicatie.',
    },
    app_module: {
      title: 'Module toevoegen',
      create: 'Module toevoegen aan %{name}',
      edit: 'Module aanpassen',
      empty: 'Nog geen modules voor deze applicatie.',
      notAvailable: 'Er zijn nog geen modules beschikbaar voor deze applicatie.',
    },
    app_licence: {
      title: 'Licentie toevoegen',
      create: 'Licentie toevoegen aan %{name}',
      edit: 'Licentie aanpassen',
      empty: 'Nog geen licenties voor deze applicatie.',
      notAvailable: 'Er zijn nog geen licenties beschikbaar voor deze applicatie.',
      siteLicence: 'Gebruikslicentie',
      users: 'Licentie voor %{amount} gebruikers',
      quantityScales: 'Licentie voor %{amount} inwoner',
    },
    app_role: {
      title: 'Rol toevoegen',
      create: 'Rol toevoegen aan %{name}',
    },
    app_report: {
      title: 'Rapportage toevoegen',
      edit: 'Rapportage aanpassen',
      create: 'Rapportage toevoegen aan %{name}',
    },
    app_organization_module: {
      empty: 'Nog geen modules geactiveerd voor deze applicatie.',
    },
    app_organization_integration: {
      empty: 'Nog geen koppelingen geactiveerd voor deze applicatie.',
    },
    auditlog: {
      name: 'Logboek',
      title: 'Logboek',
      list: 'Logboek',
      empty: 'Er is nog geen geschiedenis.',
    },
    configuration: {
      title: 'Configuratie',
      create: 'Configuratie instellen',
      edit: 'Bewerk configuratie',
    },
    app_organization: {
      title: 'Overzicht %{name}',
      create: 'Applicatie toevoegen',
      edit: '%{name} bewerken',
      apps: 'Applicaties'
    },
    organization_auth: {
      title: 'Authenticatieoverzicht',
      create: 'Authenticatie aanmaken',
      edit: 'Authenticatie bewerken',
      empty: 'Nog geen authenticatie ingesteld.',
    },
    auth_connection: {
      title: 'Koppelingoverzicht',
      create: 'Koppeling aanmaken',
      edit: 'Koppeling bewerken',
      empty: 'Nog geen koppeling ingesteld.',
      deleteTitle: 'Connection \'%{name}\' verwijderen?',
      deleteContent: 'Weet je zeker dat je deze connection wilt verwijderen?',
      connect: {
        title: 'Sign-on optie koppelen',
        tokenInput: 'Koppeltoken'
      }
    },
    auth_member: {
      name: 'Gebruiker |||| Gebruikers',
      title: 'Gebruikersoverzicht',
      list: 'Gebruikers',
      create: 'Gebruiker aanmaken',
      edit: 'Gebruiker bewerken',
      empty: 'Nog geen gebruikers toegevoegd.',
      deleteTitle: 'Gebruiker \'%{name}\' verwijderen?',
      deleteContent: 'Weet je zeker dat je deze gebruiker wilt verwijderen?',
      manageRoles: 'Rollen beheren',
      notification: {
        changePasswordError: 'Het password kon niet gereset worden.',
        confirmTitle: 'Wachtwoord resetten',
        confirmContent: 'Wil je een wachtwoord reset email versturen naar deze gebruiker?',
      }
    },
    hostalias: {
      create: 'Alias aanmaken',
      edit: 'Alias aanpassen',
      notification: {
        setDefault: {
          approved_success: 'Default host alias is gewijzigd.',
          approved_error: 'Er is iets fout gegaan met het wijzigen van de default host alias.',
        },
      },
    },
    offers: {
      list: 'Offertes',
      error: 'Het authoriseren van Mijn.xxllnc is mislukt.',
      success: 'De authorisatie is gelukt.',
      noOffers: 'Geen offertes gevonden.'
    },
    contracts: {
      list: 'Contracten',
      info: 'Hier kun je de contracten vinden die wij digitaal beschikbaar hebben voor je organisatie. '+
      'Een contract bestaat standaard uit drie onderdelen. De overeenkomst, de Service Level Agreement en de Verwerkersovereenkomst. '+
      'Het is mogelijk dat er meerdere contracten zijn afgesloten voor verschillende  applicaties. ' +
      'Staat een contract er niet bij, of klopt er iets anders niet? Neem dan contact met ons op om dit te corrigeren.',
      contactBtn: ' correctie melden',
      contactMessage: 'Er lijkt geen email client geinstalleerd te zijn. U kunt handmatig een email sturen naar %{name}.',
    },
    externalApps: {
      list: 'Externe applicaties',
      create: 'Externe applicatie toevoegen',
      edit: 'Externe applicatie bewerken',
    },
    tickets: {
      list: 'Meldingen',
      edit: 'Melding %{name}',
      create: 'Melding aanmaken',
      at: 'om',
      loggedOn: 'Aangemeld op',
      status: {
        Nieuw: 'Nieuw',
        Afgemeld: 'Afgehandeld',
      },
      reactionPlaceholder: 'Reactie versturen',
      sendReaction: 'Versturen',
    },
    ticketConfig: {
      list: 'Meldingen formulieren',
      edit: 'Melding formulier bewerken',
      create: 'Melding formulier aanmaken',
      title: 'Melding formulieren',
    }
  },
  filters: {
    title: 'Filters',
    isActive: 'Status',
    hasOrganization: 'Koppelingsstatus',
    true: 'Actief',
    false: 'Inactief',
    apps: 'Applicaties',
    domain: 'Product Team',
    linked: 'Gekoppeld',
    unlinked: 'Ongekoppeld',
    status: 'Status',
    showClosed: 'Afgehandelde meldingen tonen',
  },
  domains: {
    belastingen: 'Belastingen',
    omgeving: 'Omgeving',
    productiviteit: 'Productiviteit',
    sociaal: 'Sociaal',
    zaakgerichtWerken: 'Zaakgericht',
  },
  strategy: {
    adfs: 'Microsoft ADFS',
    auth0: 'Gebruikersnaam en wachtwoord',
    googleapps: 'Google Workspace',
    waad: 'Microsoft Azure AD',
    requiredDuringEdit: 'Bij het bewerken van een koppeling dient u deze gegevens opnieuw in te vullen.',
  },
  data: {
    endUserMenuItem: 'Gebruik',
    send: 'Versturen',
    additionalConfiguration: 'Aanvullende configuratie',
    adfsServer: 'ADFS Server',
    administratorGuideUrl: 'Beheerdersdocumentatie url',
    apiDocumentationUrl: 'API-documentatie url',
    applicationDesignTemplate: 'Template',
    applicationId: 'Application ID',
    appName: 'App',
    appOrganizationSystemTags: 'Applicatie Labels',
    apps: 'Applicatie',
    authorizationUrl: 'Authorizatie url',
    azureDomain: 'Azure AD domeinnaam',
    bannerImageHelper: 'Minimaal 813 bij 256 pixels groot',
    bruteForceProtection: 'Brute force protection',
    category: 'Categorie',
    changeType: 'Type',
    clientId: 'Client ID',
    clientSecret: 'Client secret',
    connectionType: 'Connectie type',
    demoUrl: 'URL voor meer informatie',
    description: 'Omschrijving',
    displayName: 'Gebruiker',
    documentation: 'Documentatie',
    domain: 'Domein',
    eLearningUrl: 'E-learning url',
    email: 'E-mail',
    ended: 'Einddatum',
    hexColor: 'Kleurcode',
    hexColorHelper: 'Bijvoorbeeld #FFFFFF',
    hostname: 'Hostnaam',
    icon: 'Applicatie icon (svg)',
    id: 'Id',
    identityApi: 'Identity API',
    imageUrl: 'Image url',
    information: 'Informatie',
    infoUrl: 'Informatie url',
    isPartnership: 'Samenwerkingsverband',
    link: 'Link',
    identityProviderDomains: 'Identity provider domeinen',
    logoUrl: 'Logo url',
    manifestUrl: 'Manifest url',
    mfaEnabled: 'MFA geactiveerd',
    mfaEnabledHelper: 'Wanneer MFA is ingeschakeld kan de gebruiker kiezen uit e-mail, een authenticator-app, een hardware sleutel en sms',
    mfaProvider: 'Multi-Factor Authentication (MFA)',
    mfaReturnEnrollSettings: 'MFA return enroll settings',
    name: 'Naam',
    oneTimePassword: 'Wachtwoord voor eerste keer inloggen',
    orderUrl: 'URL voor aanvragen demo',
    organization: 'Organisatie',
    organizations: 'Organisaties',
    password: 'Wachtwoord',
    passwordPolicy: 'Password Policy',
    primaryColor: 'Primaire kleur',
    primaryHostname: 'Primaire hostnaam',
    provider: 'Provider',
    secondaryColor: 'Secundaire kleur',
    secretValue: 'Secret value',
    started: 'Begindatum',
    strategy: 'Strategie',
    strategyVersion: 'Strategie versie',
    style: 'Stijl',
    summary: 'Samenvatting',
    systemTags: 'Omgeving labels',
    timestamp: 'Datum',
    tokenUrl: 'Token url',
    totalInstanceSoftLimit: 'Toegestane omgevingen per organisatie',
    totalStorageSoftLimit: 'Toegestane dataverbruik per omgeving in GB',
    url: 'Url',
    userGuideUrl: 'Gebruikersdocumentatie url',
    userId: 'Gebruiker Id',
    username: 'Gebruikersnaam',
    useWsfed: 'Gebruik WSFED',
    vendor: 'Ontwikkelaar',
    vendorUrl: 'Ontwikkelaarswebsite',
    welcomeMessage: 'Welkomsbericht',
    version: 'Versie',
    number: 'Offertenummer',
    subject: 'Onderwerp',
    isClosed: 'Afgesloten',
    billingAmount: 'Offerte bedrag:',
    vatAmount: 'BTW:',
    totalAmount: 'Totaalbedrag:',
    creationDate: 'Aangemaakt:',
    closeDate: 'Afgesloten:',
    lastMutationDate: 'Laatste update:',
    viewInTribe: 'In Tribe bekijken',
    signInTribe: 'Offerte inzien',
    exirationDate: 'Verloopt op:',
    authorizationNeeded: 'Authorizatie benodigd',
    authorizeXxllnc: 'Authorize Mijn.xxllnc',
    authorizeDescription: 'Mijn xxllnc heeft toestemming nodig om uw offertes op te halen uit Tribe CRM.',
    status: 'Status',
    documents: 'Documenten',
    download: 'Download',
    statingDate: 'Ingangsdatum',
    duration: 'Duur',
    endDate: 'Einddatum',
    startDate: 'Startdatum',
    externalApp: 'Externe applicatie',
    refresh_token: 'Refresh token',
    ticketNumber: 'Nummer',
    briefDescription: 'Omschrijving',
    created: 'Aangemaakt',
    reply: 'Antwoord toevoegen',
    closed: 'Gesloten',
    processing: 'In behandeling',
    byOperator: 'van Operator',
    closingDate: 'Afgesloten op',
    deadLine: 'Deadline',
    operator: 'Operator',
    type: 'Type',
    questionOrRequest: 'Wat is je vraag/verzoek?',
    priority: 'Welke prioriteit heeft deze vraag voor je?',
    application: 'Waar gaat het om?',
    tag: 'Applicatie of bedrijf',
    tagHelperText: 'Start met typen om te zoeken...',
    tags: 'Zoekwoorden',
    devideWithComma: 'Scheid meerdere opties met een komma',
    isRequired: 'Verplicht',
    yes: 'Ja',
    no: 'Nee',
    applicationQuestion: 'Applicatie',
    team: 'Team',
    max80characters: 'Maximaal 80 karakters',
    activeUsers: 'Actieve gebruikers: %{amount}',
    usedMemory: 'Gebruikte opslag: %{amount} GB',
    activeUsage: 'Actueel verbruik %{date}',
    inUse: '(In gebruik)',
    dataUsage: 'Dataverbruik',
    usageInGb: '%{amount} van %{max}GB verbruikt',
    overLimit: '%{limit}GB boven %{amount}GB limiet',
    amount: 'Aantal',
    inactive: 'Inactieve %{description}',
    activate: 'Activeer',
    app: 'Applicatie',
    app_instance: 'Omgeving',
    app_instances: 'Omgevingen',
    new: 'Nieuwe',
    active: 'Actieve',
    recentLogs: 'Laatste logs',
  },
  support: {
    title: 'Snel een oplossing vinden',
    empty: 'Er zijn geen trainingen of gidsen beschikbaar voor %{name}. \n' +
      'Klik rechts onder op het vraagteken voor persoonlijke ondersteuning.',
    eLearning: {
      title: 'E-learning',
      description: 'Leer alles over wat je kunt doen in xxllnc Zaken',
      button: 'Bekijk de trainingen',
    },
    userGuide: {
      title: 'Gebruikersgids',
      description: 'Lees meer over het gebruik van xxllnc Zaken',
      button: 'Bekijk de trainingen',
    },
    administratorGuide: {
      title: 'Beheerdersgids',
      description: 'Lees hoe je jouw gebruikers kunt faciliteren',
      button: 'Lees de gebruikersgids',
    },
    apiDocumentation: {
      title: 'API documentatie',
      description: 'Lees wat er mogelijk is met onze API’s',
      button: 'Lees de documentatie',
    },
  },
  validation: {
    color: 'Moet een HTML kleurcode zijn.',
    hostname: 'Ongeldige hostname.',
    systemName: 'Mag alleen uit letters, cijfers en \'-\' bestaan.',
  },
  show: {
    details: 'Overzicht',
    apps: 'Applicaties',
    environments: 'Omgevingen',
    auth: 'Authenticatie',
    integration: 'Koppelingen',
    module: 'Modules',
    auditLog: 'Logging',
    signOn: 'Sign on',
    member: 'Gebruikers',
    questions: 'Vragen',
    roles: 'Rollen',
    report: 'Rapportages',
    licence: 'Licenties',
  },
  edit: {
    title: 'Bewerk %{name}',
  },
  submenu: {
    settings: 'Beheer',
  },
  actions: {
    createTicket: 'Melding aanmaken',
    activate: 'Aanvragen',
    add: 'Toevoegen',
    added: 'Toegevoegd',
    active: 'In gebruik',
    addMember: 'Nieuwe gebruiker',
    cancel: 'Annuleren',
    close: 'Sluiten',
    connect: 'Koppelen',
    create: 'Aanmaken',
    delete: 'Verwijder',
    done: 'Klaar',
    download: 'downloaden',
    downloadExampleCSV: 'Voorbeeld downloaden (CSV)',
    edit: 'Bewerken',
    goBack: 'Ga terug',
    info: 'Meer informatie',
    link: 'Linken',
    loginDigid: 'Inloggen met DigiD',
    relate: 'Relateren',
    integrations: 'Koppelen',
    modules: 'Module integreren',
    resetPassword: 'Reset wachtwoord',
    showAllApps: 'Toon overige applicaties (%{count})',
    showLess: 'Toon minder',
    closeTicket: 'Melding sluiten',
    role: 'Rol toevoegen',
    report: 'Rapportage toevoegen',
    licences: 'Licentie toevoegen',
    copyId: 'Kopieer ID',
  },
  message: {
    deleteTitle: 'Item \'%{name}\' verwijderen?',
  },
  notification: {
    warning: 'Er iets mis gegaan.',
    token: {
      content: 'Deel deze code: %{token}',
      error: 'Er is iets fout gegaan met het genereren van een koppeltoken.',
      title: 'Er is een token gegenereerd.',
    },
    authMemberRole: {
      warning: 'Er is iets fout gegaan met het verwijderen van de rol.',
    },
  },
  login: {
    title: 'Inloggen',
    button: 'Inloggen',
    choose: 'Kies voor welke organisatie je wilt inloggen:',
    email: 'Email',
    emailHint: 'Vul je emailadres in om door te gaan.',
    fetchUserInfo: 'Een moment geduld, we halen je gebruikersinformatie op.',
    hint: 'Log in op mijn.xxllnc.nl',
    incognitoError: 'Authenticatie niet mogelijk in incognito mode',
    loginCompleted: 'Succesvol ingelogd!',
    unknownOrganizationId: 'Het opgegeven organisatie-id is onbekend',
    notValid: 'Het opgegeven emailadres is niet geldig',
    byXxllnc: 'mijn xxllnc is een applicatie van xxllnc',
  },
  page: {
    unauthorized: {
      headerTitle: 'Onbevoegd',
      defaultTitle: 'Onbevoegd',
      title: 'Voor deze actie ben je niet bevoegd.',
      text: 'Je hebt geprobeerd een actie uit te voeren waar je geen rechten voor hebt.',
    },
    digid: {
      title: 'Inloggen met DigiD',
      text: 'Voor het door u gekozen onderdeel is verificatie van uw identiteit vereist. \n' +
        'Wanneer u op de button van DigiD klikt, wordt u doorgestuurd naar de omgeving van \n' +
        'DigiD. Nadat u bent ingelogd, wordt u automatisch teruggestuurd naar het door u gekozen onderdeel.',
      infoTitle1: 'Wat is DigiD?',
      infoText1: 'DigiD staat voor Digitale Identiteit; het is een gemeenschappelijk systeem \n' +
        'waarmee de overheid op internet uw identiteit kan verifiëren. Met uw DigiD kunt u bij \n' +
        'steeds meer overheidsinstellingen terecht.',
      infoTitle2: 'Nog geen DigiD inlogcode?',
    },
  }
}
