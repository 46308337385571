/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

/** AppCategories */
export enum AppCategories {
  Belastingen = "belastingen",
  Omgeving = "omgeving",
  Productiviteit = "productiviteit",
  Sociaal = "sociaal",
  ZaakgerichtWerken = "zaakgerichtWerken",
}

/** AppCreate */
export interface AppCreate {
  /**
   * Hex color
   * A hexadecimal color
   * @default "#ffffff"
   * @pattern ^#[a-fA-F0-9]{6}$
   */
  hexColor?: string;
  /**
   * Manifest url
   * The url to the manifest of the application
   */
  manifestUrl?: string | null;
  /**
   * Demo url
   * The url to the demo application
   */
  demoUrl?: string | null;
  /**
   * App image url
   * The url to the image/logo of the app
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  imageUrl: string;
  /**
   * Category
   * The category(domain) of the app
   */
  category: AppCategories;
  /** App order url */
  orderUrl?: string | null;
  /** App name */
  name: string;
  /** App description */
  description: string;
  /** App icon */
  icon: string;
  /** App vendor */
  vendor?: string | null;
  /** App vendor url */
  vendorUrl?: string | null;
  /**
   * Soft limit for total instances.
   * Value is zero or higher.
   * @min 0
   */
  totalInstanceSoftLimit: number;
  /**
   * Soft limit for total storage.
   * Value is zero or higher and unit is gigabytes.
   * @min 0
   */
  totalStorageSoftLimit: number;
  /** Design template for app. */
  applicationDesignTemplate?: string | null;
  /** Additional configuration for app in JSON format specific for an app. */
  additionalConfiguration?: object | null;
  /** App eLearning url */
  eLearningUrl?: string | null;
  /** App user guide url */
  userGuideUrl?: string | null;
  /** App administrator guide url */
  administratorGuideUrl?: string | null;
  /** App api documentation url */
  apiDocumentationUrl?: string | null;
  /**
   * Start date for this app.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this app.
   * If this field is not specified, the app will not have an end date.
   */
  ended?: string | null;
}

/** AppInstanceConfigurationCreate */
export interface AppInstanceConfigurationCreate {
  /**
   * Unique identifier of the app instance
   * @format uuid
   */
  appInstanceId: string;
  /** Design template for app. */
  applicationDesignTemplate?: string | null;
  /** Additional configuration for app in a JSON format specific for an app */
  additionalConfiguration?: object | null;
  /**
   * The initial password for instances of the app.
   * This field will be cleared when the provisioner successfully changes the state of the related instance to 'updated'.
   */
  oneTimePassword?: string | null;
  /**
   * Start date of the app instance.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this app instance.
   * If this field is not specified, the app instance will not have an end date.
   */
  ended?: string | null;
}

/** AppInstanceConfigurationOut */
export interface AppInstanceConfigurationOut {
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the app instance
   * @format uuid
   */
  appInstanceId: string;
  /** Design template for app. */
  applicationDesignTemplate?: string | null;
  /** Additional configuration for app in a JSON format specific for an app */
  additionalConfiguration?: object | null;
  /**
   * The initial password for instances of the app.
   * This field will be cleared when the provisioner successfully changes the state of the related instance to 'updated'.
   */
  oneTimePassword?: string | null;
  /**
   * Start date of the app instance.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this app instance.
   * If this field is not specified, the app instance will not have an end date.
   */
  ended?: string | null;
  /**
   * Unique identifier of the app instance configuration
   * @format uuid
   */
  id: string;
}

/** AppInstanceConfigurationUpdate */
export interface AppInstanceConfigurationUpdate {
  /**
   * Unique identifier of the app instance
   * @format uuid
   */
  appInstanceId: string;
  /** Design template for app. */
  applicationDesignTemplate?: string | null;
  /** Additional configuration for app in a JSON format specific for an app */
  additionalConfiguration?: object | null;
  /**
   * The initial password for instances of the app.
   * This field will be cleared when the provisioner successfully changes the state of the related instance to 'updated'.
   */
  oneTimePassword?: string | null;
  /**
   * Start date of the app instance.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this app instance.
   * If this field is not specified, the app instance will not have an end date.
   */
  ended?: string | null;
}

/** AppInstanceCreate */
export interface AppInstanceCreate {
  /** App instance description */
  description: string;
  /** Unique identifier of the organization */
  organizationId?: string | null;
  /**
   * Start date for this app instance.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this app instance.
   * If this field is not specified, the app instance will not have an end date.
   */
  ended?: string | null;
  /** List of tags that can be used to identify or select an AppInstance. */
  systemTags?: string[] | null;
  /**
   * Unique identifier of the app
   * @format uuid
   */
  appId: string;
}

/** AppInstanceCreateManyArray */
export interface AppInstanceCreateManyArray {
  /** A collection of app instances to import. */
  data: AppInstanceCreateManyItem[];
}

/** AppInstanceCreateManyArrayOut */
export interface AppInstanceCreateManyArrayOut {
  /** The import result for a colleciton of app instances. */
  data: AppInstanceCreateManyItemOut[];
}

/**
 * AppInstanceCreateManyItem
 * This type is specific for importing app instances that originate from another system, like
 * exported from a CRM system.
 */
export interface AppInstanceCreateManyItem {
  /**
   * Integration type
   * Indication of the type integration.
   */
  integration_type: string;
  /**
   * Integration key
   * The key or id of the app instance in the system the app instance originates from, like a CRM. If later the same app instance is created (or imported) again, it is clear to update it instead of creating a new one.
   */
  integration_key: string;
  /** App instance description */
  description: string;
  /** Unique identifier of the organization */
  organizationId?: string | null;
  /**
   * Start date for this app instance.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this app instance.
   * If this field is not specified, the app instance will not have an end date.
   */
  ended?: string | null;
  /** List of tags that can be used to identify or select an AppInstance. */
  systemTags?: string[] | null;
  /**
   * Unique identifier of the app
   * @format uuid
   */
  appId: string;
  /** The hostnames for this app instance */
  hostnames: string[];
}

/** AppInstanceCreateManyItemOut */
export interface AppInstanceCreateManyItemOut {
  /**
   * Integration type
   * Indication of the type integration.
   */
  integration_type: string;
  /**
   * Integration key
   * The key or id of the app instance in the system the app instance originates from, like a CRM. If later the same app instance is created (or imported) again, it is clear to update it instead of creating a new one.
   */
  integration_key: string;
  /** The result. */
  result: string;
}

/** AppInstanceLogAddOns */
export interface AppInstanceLogAddOns {
  /**
   * Unique identifier of the add-on.
   * @format uuid
   */
  id: string;
  /** Status of the add-on. */
  enabled: boolean;
}

/** AppInstanceLogAddOnsWithUsage */
export interface AppInstanceLogAddOnsWithUsage {
  /**
   * Unique identifier of the add-on.
   * @format uuid
   */
  id: string;
  /** Status of the add-on. */
  enabled: boolean;
  /** Usage of the add-on. */
  usage: number;
}

/** AppInstanceLogCreate */
export interface AppInstanceLogCreate {
  /**
   * Unique identifier of the app.
   * @format uuid
   */
  appId: string;
  /**
   * Unique identifier of the organization.
   * @format uuid
   */
  organizationId: string;
  /** Description of the app instance. */
  description?: string | null;
  /** The number of active users in the app instance. */
  activeUsers: number;
  /** The used memory of the app instance. */
  usedMemory: number;
  /** Any data that should be stored. */
  data?: object | null;
  /** A collection of modules. */
  modules?: AppInstanceLogAddOns[] | null;
  /** A collection of integrations. */
  integrations?: AppInstanceLogAddOns[] | null;
  /** A collection of licences. */
  licences?: AppInstanceLogAddOnsWithUsage[] | null;
}

/** AppInstanceLogCreateManyArray */
export interface AppInstanceLogCreateManyArray {
  /** A collection of application registrations to import. */
  logs: AppInstanceLogCreate[];
}

/** AppInstanceLogCreateManyArrayOut */
export interface AppInstanceLogCreateManyArrayOut {
  /** The import result for a collection of application registrations. */
  logs: AppInstanceLogCreateManyItemOut[];
}

/** AppInstanceLogCreateManyItemOut */
export interface AppInstanceLogCreateManyItemOut {
  /** The result. */
  result: string;
}

/** AppInstanceLogOut */
export interface AppInstanceLogOut {
  /**
   * Unique identifier of the app instance.
   * @format uuid
   */
  appInstanceId: string;
  /** The number of active users in the app instance. */
  activeUsers: number;
  /** The used memory of the app instance. */
  usedMemory: number;
  /** Any data that should be stored. */
  data: object | null;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the app instance log
   * @format uuid
   */
  id: string;
  /** The name of the app. */
  appName: string;
  /** The name of the organization. */
  orgName: string;
  /** The description of the app instance. */
  description: string;
}

/** AppInstanceOut */
export interface AppInstanceOut {
  /** App instance description */
  description: string;
  /** Unique identifier of the organization */
  organizationId?: string | null;
  /**
   * Start date for this app instance.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this app instance.
   * If this field is not specified, the app instance will not have an end date.
   */
  ended?: string | null;
  /** List of tags that can be used to identify or select an AppInstance. */
  systemTags?: string[] | null;
  /**
   * Unique identifier of the app
   * @format uuid
   */
  appId: string;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the app instance
   * @format uuid
   */
  id: string;
  /** App name */
  appName: string;
  /** Primary hostalias */
  primaryHostname?: string | null;
  /** Unique identifier of the app organization */
  appOrganizationId?: string | null;
  /** List of tags that can be used to identify or select an AppInstance. */
  appOrganizationSystemTags?: string[] | null;
}

/** AppInstanceStatus */
export enum AppInstanceStatus {
  Error = "error",
  Pending = "pending",
  Updating = "updating",
  Uptodate = "uptodate",
}

/** AppInstanceUpdate */
export interface AppInstanceUpdate {
  /** App instance description */
  description: string;
  /** Unique identifier of the organization */
  organizationId?: string | null;
  /**
   * Start date for this app instance.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this app instance.
   * If this field is not specified, the app instance will not have an end date.
   */
  ended?: string | null;
  /** List of tags that can be used to identify or select an AppInstance. */
  systemTags?: string[] | null;
  /** New deleted date for this AppInstance */
  deleted?: string | null;
}

/** AppIntegrationCreate */
export interface AppIntegrationCreate {
  /** The name of the integration of the app. */
  name: string;
  /** The description of the integration of the app. */
  description: string;
  /**
   * Unique identifier of the app
   * @format uuid
   */
  appId: string;
}

/** AppIntegrationOut */
export interface AppIntegrationOut {
  /** The name of the integration of the app. */
  name: string;
  /** The description of the integration of the app. */
  description: string;
  /**
   * Unique identifier of the integration of the app.
   * @format uuid
   */
  id: string;
  /** Unique identifier of the app. */
  appId?: string | null;
  /** Whether the integration is active for the organization of the user. */
  isActiveForMyOrganization?: boolean | null;
}

/** AppIntegrationUpdate */
export interface AppIntegrationUpdate {
  /** The name of the integration of the app. */
  name: string;
  /** The description of the integration of the app. */
  description: string;
}

/** AppLicenceCreate */
export interface AppLicenceCreate {
  /**
   * Type
   * The type of the licence
   */
  type: LicenceTypes;
  /** The name of the licence of the app. */
  name: string;
  /** The description of the licence of the app. */
  description: string;
  /**
   * Unique identifier of the app
   * @format uuid
   */
  appId: string;
}

/** AppLicenceOut */
export interface AppLicenceOut {
  /**
   * Type
   * The type of the licence
   */
  type: LicenceTypes;
  /** The name of the licence of the app. */
  name: string;
  /** The description of the licence of the app. */
  description: string;
  /**
   * Unique identifier of the licence of the app.
   * @format uuid
   */
  id: string;
  /**
   * Unique identifier of the app.
   * @format uuid
   */
  appId: string;
}

/** AppLicenceUpdate */
export interface AppLicenceUpdate {
  /**
   * Type
   * The type of the licence
   */
  type: LicenceTypes;
  /** The name of the licence of the app. */
  name: string;
  /** The description of the licence of the app. */
  description: string;
}

/** AppModuleCreate */
export interface AppModuleCreate {
  /** The name of the module of the app. */
  name: string;
  /** The description of the module of the app. */
  description: string;
  /**
   * Unique identifier of the app
   * @format uuid
   */
  appId: string;
}

/** AppModuleOut */
export interface AppModuleOut {
  /** The name of the module of the app. */
  name: string;
  /** The description of the module of the app. */
  description: string;
  /**
   * Unique identifier of the module of the app.
   * @format uuid
   */
  id: string;
  /** Unique identifier of the app. */
  appId?: string | null;
  /** Whether the module is active for the organization of the user. */
  isActiveForMyOrganization?: boolean | null;
}

/** AppModuleUpdate */
export interface AppModuleUpdate {
  /** The name of the module of the app. */
  name: string;
  /** The description of the module of the app. */
  description: string;
}

/** AppOrganizationCreate */
export interface AppOrganizationCreate {
  /**
   * Soft limit for total instances.
   * Value is zero or higher.
   * @min 0
   */
  totalInstanceSoftLimit: number;
  /**
   * Soft limit for total storage.
   * Value is zero or higher and unit is bytes.
   * @min 0
   */
  totalStorageSoftLimit: number;
  /** Design template for app. */
  applicationDesignTemplate?: string | null;
  /** Additional configuration for app in a JSON format specific for an app. */
  additionalConfiguration?: object | null;
  /** List of tags that can be used to identify or select an AppOrganization. */
  systemTags?: string[] | null;
  /**
   * Start date for this app organization.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this app organizatino.
   * If not specified, the app organization will not have an end date.
   */
  ended?: string | null;
  /**
   * Unique identifier of the app
   * @format uuid
   */
  appId: string;
  /**
   * Unique identifier of the organization
   * @format uuid
   */
  organizationId: string;
}

/** AppOrganizationIntegrationCreate */
export interface AppOrganizationIntegrationCreate {
  /**
   * Unique identifier of the app organization
   * @format uuid
   */
  appOrganizationId: string;
  /**
   * Unique identifier of the app integration
   * @format uuid
   */
  appIntegrationId: string;
}

/** AppOrganizationIntegrationOut */
export interface AppOrganizationIntegrationOut {
  /**
   * Unique identifier of the app organization
   * @format uuid
   */
  appOrganizationId: string;
  /**
   * Unique identifier of the app integration
   * @format uuid
   */
  appIntegrationId: string;
  /**
   * Unique identifier of the app organization integration
   * @format uuid
   */
  id: string;
  /** The name of the app integration */
  appIntegrationName: string;
  /** The description of the app integration */
  appIntegrationDescription: string;
}

/** AppOrganizationIntegrationUpdate */
export interface AppOrganizationIntegrationUpdate {
  /**
   * Unique identifier of the app organization
   * @format uuid
   */
  appOrganizationId: string;
  /**
   * Unique identifier of the app integration
   * @format uuid
   */
  appIntegrationId: string;
}

/** AppOrganizationLicenceCreate */
export interface AppOrganizationLicenceCreate {
  /**
   * Unique identifier of the app organization
   * @format uuid
   */
  appOrganizationId: string;
  /**
   * Unique identifier of the app licence
   * @format uuid
   */
  appLicenceId: string;
  /**
   * Amount for licence.
   * Value is zero or higher.
   */
  amount?: number | null;
}

/** AppOrganizationLicenceOut */
export interface AppOrganizationLicenceOut {
  /**
   * Unique identifier of the app organization
   * @format uuid
   */
  appOrganizationId: string;
  /**
   * Unique identifier of the app licence
   * @format uuid
   */
  appLicenceId: string;
  /**
   * Amount for licence.
   * Value is zero or higher.
   */
  amount?: number | null;
  /**
   * Unique identifier of the app organization licence
   * @format uuid
   */
  id: string;
  /** The type of the app licence */
  type: string;
  /** The name of the app licence */
  appLicenceName: string;
  /** The description of the app licence */
  appLicenceDescription: string;
}

/** AppOrganizationLicenceUpdate */
export interface AppOrganizationLicenceUpdate {
  /**
   * Unique identifier of the app organization
   * @format uuid
   */
  appOrganizationId: string;
  /**
   * Unique identifier of the app licence
   * @format uuid
   */
  appLicenceId: string;
  /**
   * Amount for licence.
   * Value is zero or higher.
   */
  amount?: number | null;
}

/** AppOrganizationModuleCreate */
export interface AppOrganizationModuleCreate {
  /**
   * Unique identifier of the app organization
   * @format uuid
   */
  appOrganizationId: string;
  /**
   * Unique identifier of the app module
   * @format uuid
   */
  appModuleId: string;
}

/** AppOrganizationModuleOut */
export interface AppOrganizationModuleOut {
  /**
   * Unique identifier of the app organization
   * @format uuid
   */
  appOrganizationId: string;
  /**
   * Unique identifier of the app module
   * @format uuid
   */
  appModuleId: string;
  /**
   * Unique identifier of the app organization module
   * @format uuid
   */
  id: string;
  /** The name of the app module */
  appModuleName: string;
  /** The description of the app module */
  appModuleDescription: string;
}

/** AppOrganizationModuleUpdate */
export interface AppOrganizationModuleUpdate {
  /**
   * Unique identifier of the app organization
   * @format uuid
   */
  appOrganizationId: string;
  /**
   * Unique identifier of the app module
   * @format uuid
   */
  appModuleId: string;
}

/** AppOrganizationOut */
export interface AppOrganizationOut {
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Soft limit for total instances.
   * Value is zero or higher.
   * @min 0
   */
  totalInstanceSoftLimit: number;
  /**
   * Soft limit for total storage.
   * Value is zero or higher and unit is bytes.
   * @min 0
   */
  totalStorageSoftLimit: number;
  /** Design template for app. */
  applicationDesignTemplate?: string | null;
  /** Additional configuration for app in a JSON format specific for an app. */
  additionalConfiguration?: object | null;
  /** List of tags that can be used to identify or select an AppOrganization. */
  systemTags?: string[] | null;
  /**
   * Start date for this app organization.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this app organizatino.
   * If not specified, the app organization will not have an end date.
   */
  ended?: string | null;
  /**
   * Unique identifier of the app organization
   * @format uuid
   */
  id: string;
  /**
   * Unique identifier of the app
   * @format uuid
   */
  appId: string;
  /** Name of the app */
  appName: string;
  /**
   * Unique identifier of the organization
   * @format uuid
   */
  organizationId: string;
}

/** AppOrganizationUpdate */
export interface AppOrganizationUpdate {
  /**
   * Soft limit for total instances.
   * Value is zero or higher.
   * @min 0
   */
  totalInstanceSoftLimit: number;
  /**
   * Soft limit for total storage.
   * Value is zero or higher and unit is bytes.
   * @min 0
   */
  totalStorageSoftLimit: number;
  /** Design template for app. */
  applicationDesignTemplate?: string | null;
  /** Additional configuration for app in a JSON format specific for an app. */
  additionalConfiguration?: object | null;
  /** List of tags that can be used to identify or select an AppOrganization. */
  systemTags?: string[] | null;
  /**
   * Start date for this app organization.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this app organizatino.
   * If not specified, the app organization will not have an end date.
   */
  ended?: string | null;
  /** New deleted date for this AppOrganization */
  deleted?: string | null;
}

/** AppOut */
export interface AppOut {
  /**
   * Hex color
   * A hexadecimal color
   * @default "#ffffff"
   * @pattern ^#[a-fA-F0-9]{6}$
   */
  hexColor?: string;
  /**
   * Manifest url
   * The url to the manifest of the application
   */
  manifestUrl?: string | null;
  /**
   * Demo url
   * The url to the demo application
   */
  demoUrl?: string | null;
  /**
   * App image url
   * The url to the image/logo of the app
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  imageUrl: string;
  /**
   * Category
   * The category(domain) of the app
   */
  category: AppCategories;
  /** App order url */
  orderUrl?: string | null;
  /** App name */
  name: string;
  /** App description */
  description: string;
  /** App icon */
  icon: string;
  /** App vendor */
  vendor?: string | null;
  /** App vendor url */
  vendorUrl?: string | null;
  /**
   * Soft limit for total instances.
   * Value is zero or higher.
   * @min 0
   */
  totalInstanceSoftLimit: number;
  /**
   * Soft limit for total storage.
   * Value is zero or higher and unit is gigabytes.
   * @min 0
   */
  totalStorageSoftLimit: number;
  /** Design template for app. */
  applicationDesignTemplate?: string | null;
  /** Additional configuration for app in JSON format specific for an app. */
  additionalConfiguration?: object | null;
  /** App eLearning url */
  eLearningUrl?: string | null;
  /** App user guide url */
  userGuideUrl?: string | null;
  /** App administrator guide url */
  administratorGuideUrl?: string | null;
  /** App api documentation url */
  apiDocumentationUrl?: string | null;
  /**
   * Start date for this app.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this app.
   * If this field is not specified, the app will not have an end date.
   */
  ended?: string | null;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the app
   * @format uuid
   */
  id: string;
}

/** AppOverview */
export interface AppOverview {
  /**
   * Hex color
   * A hexadecimal color
   * @default "#ffffff"
   * @pattern ^#[a-fA-F0-9]{6}$
   */
  hexColor?: string;
  /**
   * Demo url
   * The url to the demo application
   */
  demoUrl?: string | null;
  /**
   * App image url
   * The url to the image/logo of the app
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  imageUrl: string;
  /**
   * Category
   * The category(domain) of the app
   */
  category: AppCategories;
  /** App order url */
  orderUrl?: string | null;
  /**
   * Unique identifier of the app instance
   * @format uuid
   */
  id: string;
  /** Name of the app */
  name?: string | null;
  /** Description of the app */
  description: string;
  /** App icon */
  icon: string;
  /** App vendor */
  vendor?: string | null;
  /** App vendor url */
  vendorUrl?: string | null;
  /** App eLearning url */
  eLearningUrl?: string | null;
  /** App user guide url */
  userGuideUrl?: string | null;
  /** App administrator guide url */
  administratorGuideUrl?: string | null;
  /** App api documentation url */
  apiDocumentationUrl?: string | null;
  /**
   * Is active for organization
   * Indicates if the app is active for the organization
   * @default false
   */
  isActiveForMyOrganization?: boolean | null;
}

/** AppReportCreate */
export interface AppReportCreate {
  /**
   * Unique identifier of the app.
   * @format uuid
   */
  appId: string;
  /** The name of the Report of the app. */
  name: string;
  /** The url of the Report of the app. */
  url: string;
}

/** AppReportOut */
export interface AppReportOut {
  /**
   * Unique identifier of the Report of the app.
   * @format uuid
   */
  id: string;
  /**
   * Unique identifier of the app.
   * @format uuid
   */
  appId: string;
  /** The name of the Report of the app. */
  name: string;
  /** The url of the Report of the app. */
  url: string;
}

/** AppReportUpdate */
export interface AppReportUpdate {
  /** The name of the Report of the app. */
  name?: string | null;
  /** The url of the Report of the app. */
  url?: string | null;
}

/** AppRoleCreate */
export interface AppRoleCreate {
  /**
   * Unique identifier of the app.
   * @format uuid
   */
  appId: string;
  /** The label of the role of the app. */
  label: string;
  /** The description of the role of the app. */
  description: string;
}

/** AppRoleOut */
export interface AppRoleOut {
  /**
   * Unique identifier of the app.
   * @format uuid
   */
  appId: string;
  /** The label of the role of the app. */
  label: string;
  /** The description of the role of the app. */
  description: string;
  /**
   * Unique identifier of the role of the app.
   * @format uuid
   */
  id: string;
  /** The auth0 identifier of the role */
  auth0RoleId: string;
}

/** AppRoleUpdate */
export interface AppRoleUpdate {
  /**
   * Unique identifier of the app.
   * @format uuid
   */
  appId: string;
  /** The label of the role of the app. */
  label: string;
  /** The description of the role of the app. */
  description: string;
}

/** AppStyling */
export interface AppStyling {
  /** The name of the app */
  name: string;
  /** App icon */
  icon: string;
  /**
   * App image url
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  imageUrl: string;
  /** Hex color of the app */
  hexColor: string;
}

/** AppUpdate */
export interface AppUpdate {
  /**
   * Hex color
   * A hexadecimal color
   * @default "#ffffff"
   * @pattern ^#[a-fA-F0-9]{6}$
   */
  hexColor?: string;
  /**
   * Manifest url
   * The url to the manifest of the application
   */
  manifestUrl?: string | null;
  /**
   * Demo url
   * The url to the demo application
   */
  demoUrl?: string | null;
  /**
   * App image url
   * The url to the image/logo of the app
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  imageUrl: string;
  /**
   * Category
   * The category(domain) of the app
   */
  category: AppCategories;
  /** App order url */
  orderUrl?: string | null;
  /** App name */
  name: string;
  /** App description */
  description: string;
  /** App icon */
  icon: string;
  /** App vendor */
  vendor?: string | null;
  /** App vendor url */
  vendorUrl?: string | null;
  /**
   * Soft limit for total instances.
   * Value is zero or higher.
   * @min 0
   */
  totalInstanceSoftLimit: number;
  /**
   * Soft limit for total storage.
   * Value is zero or higher and unit is gigabytes.
   * @min 0
   */
  totalStorageSoftLimit: number;
  /** Design template for app. */
  applicationDesignTemplate?: string | null;
  /** Additional configuration for app in JSON format specific for an app. */
  additionalConfiguration?: object | null;
  /** App eLearning url */
  eLearningUrl?: string | null;
  /** App user guide url */
  userGuideUrl?: string | null;
  /** App administrator guide url */
  administratorGuideUrl?: string | null;
  /** App api documentation url */
  apiDocumentationUrl?: string | null;
  /**
   * Start date for this app.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this app.
   * If this field is not specified, the app will not have an end date.
   */
  ended?: string | null;
  /** New deleted date for this app */
  deleted?: string | null;
}

/** ApplicationState */
export interface ApplicationState {
  /** Organization */
  organization?: OrganizationSummary | null;
  /** Configuration */
  configuration: ConfigurationOut;
}

/** AuthConnectionBase */
export interface AuthConnectionBase {
  /**
   * The connection name
   * @maxLength 44
   */
  name: string;
  /** Connection id */
  id: string;
  /** The strategy of the connection */
  strategy: Strategy;
  /**
   * Unique identifier of the related organization auth
   * @format uuid
   */
  organizationAuthId: string;
}

/** AuthConnectionCreate */
export interface AuthConnectionCreate {
  /** Parameters to add a new Auth0 username/password connection and add the connection to the organization */
  auth0?: ConnectionAuth0Connection | null;
  /** Parameters to add a Window Azure AD connection and add the connection to the organization */
  waad?: ConnectionAzureADConnection | null;
  /** Parameters to add a ADFS connection and add the connection to the organization */
  adfs?: ConnectionAdfsConnection | null;
  /** Parameters to add the new google-apps connection and add the connection to the organization */
  googleapps?: ConnectionGoogleAppsConnection | null;
  /** The id of the provider (organization/auth) the connection belongs to. Can only be specified with admin permissions, otherwise ignored and the connection is assumed to belong to the organization of the current user. */
  organizationAuthId?: string | null;
}

/** AuthConnectionCreateByToken */
export interface AuthConnectionCreateByToken {
  /** The id of the provider (organization/auth) the connection belongs to. Can only be specified with admin permissions, otherwise ignored and the connection is assumed to belong to the organization of the current user. */
  organizationAuthId?: string | null;
  /** A token to share a connection with another organization */
  token: string;
}

/** AuthConnectionOut */
export interface AuthConnectionOut {
  /**
   * The connection name
   * @maxLength 44
   */
  name: string;
  /** Connection id */
  id: string;
  /** The strategy of the connection */
  strategy: Strategy;
  /**
   * Unique identifier of the related organization auth
   * @format uuid
   */
  organizationAuthId: string;
  /** List of hostnames */
  hostnames: string[];
  /** These are the options of the connection */
  options?: null;
  /**
   * Multifactor authentication enabled flag
   * Flag indicating whether multifactor authentication is enabled for the connection
   */
  mfaEnabled?: boolean | null;
}

/** AuthConnectionUpdate */
export interface AuthConnectionUpdate {
  /** Parameters to add a new Auth0 username/password connection and add the connection to the organization */
  auth0?: ConnectionAuth0Connection | null;
  /** Parameters to add a Window Azure AD connection and add the connection to the organization */
  waad?: ConnectionAzureADConnection | null;
  /** Parameters to add a ADFS connection and add the connection to the organization */
  adfs?: ConnectionAdfsConnection | null;
  /** Parameters to add the new google-apps connection and add the connection to the organization */
  googleapps?: ConnectionGoogleAppsConnection | null;
}

/** AuthMemberAppsOut */
export interface AuthMemberAppsOut {
  /** The name of the app */
  name: string;
  /** The description of the role */
  description: string;
  /** The auth0 identifier of the role */
  id: string;
  /**
   * The id of the app
   * @format uuid
   */
  appId: string;
  /** The name of the app */
  appName: string;
}

/** AuthMemberRoleId */
export interface AuthMemberRoleId {
  /** The id of the organization member. */
  authMemberId: string;
  /**
   * The auth0 identifiers of the roles to add or delete
   * @format uuid
   */
  auth0RoleId: string;
}

/** AuthMemberRolesIdOut */
export interface AuthMemberRolesIdOut {
  /** The id of the organization member. */
  id: string;
}

/** Branch */
export interface Branch {
  /**
   * Unique identifier of the branch
   * This represents the id value in the TopDesk branch
   * @format uuid
   */
  id: string;
  /**
   * The name of the branch
   * This represents the name value in the TopDesk branch
   */
  name: string | null;
}

/** Category */
export interface Category {
  /**
   * Unique identifier of the category
   * This represents the id value in the TopDesk incident category
   * @format uuid
   */
  id: string;
  /**
   * The name of the category
   * This represents the name value in the TopDesk incident category
   */
  name: string | null;
}

/** ConfigurationOut */
export interface ConfigurationOut {
  /**
   * Unique identifier
   * @format uuid
   */
  id: string;
  /** Welcome message */
  welcomeMessage?: string | null;
}

/** ConfigurationUpdate */
export interface ConfigurationUpdate {
  /** Welcome message */
  welcomeMessage?: string | null;
}

/** ConnectionAdfsConnection */
export interface ConnectionAdfsConnection {
  /**
   * The connection name
   * @maxLength 44
   */
  name: string;
  /** List of hostnames */
  hostnames: string[];
  /** Options of the ADFS connection */
  options: ConnectionAdfsOptions;
}

/** ConnectionAdfsOptions */
export interface ConnectionAdfsOptions {
  /**
   * The metadata URL of the ADFS server.
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  adfs_server: string;
}

/** ConnectionAuth0Connection */
export interface ConnectionAuth0Connection {
  /**
   * The connection name
   * @maxLength 44
   */
  name: string;
  /** List of hostnames */
  hostnames: string[];
  /** Options of the Auth0 username/password connection */
  options: ConnectionAuth0Options;
}

/** ConnectionAuth0Options */
export interface ConnectionAuth0Options {
  /** Multifactor authentication settings */
  mfa?: ConnectionAuth0OptionsMFA | null;
  /** Flag indicating multifactor authentication is enabled */
  mfaEnabled?: boolean | null;
  /** The password policy */
  passwordPolicy?: string | null;
  /** The strategy version */
  strategy_version?: number | null;
  /** Flag indicating whether brute force protection is enabled */
  brute_force_protection?: boolean | null;
}

/** ConnectionAuth0OptionsMFA */
export interface ConnectionAuth0OptionsMFA {
  /** Flag indicating whether multifactor authentication is active */
  active: string;
  /** Flag indicating whether enroll settings must be returned */
  return_enroll_settings: string;
}

/** ConnectionAzureADConnection */
export interface ConnectionAzureADConnection {
  /**
   * The connection name
   * @maxLength 44
   */
  name: string;
  /** List of hostnames */
  hostnames: string[];
  /** Options of the Azure AD connection */
  options: ConnectionAzureADOptions;
}

/** ConnectionAzureADOptions */
export interface ConnectionAzureADOptions {
  /** Domain */
  domain: string;
  /** The client id of Azure AD */
  applicationId: string;
  /** The secret value of Azure AD */
  secretValue: string;
  /** Identity API */
  identityApi: IdentityApi;
}

/** ConnectionGoogleAppsConnection */
export interface ConnectionGoogleAppsConnection {
  /**
   * The connection name
   * @maxLength 44
   */
  name: string;
  /** List of hostnames */
  hostnames: string[];
  /** Options of the Auth0 username/password connection */
  options: ConnectionGoogleAppsOptions;
}

/** ConnectionGoogleAppsOptions */
export interface ConnectionGoogleAppsOptions {
  /** The Google workspace domain. */
  domain: string;
  /** The client id of the Google apps server. */
  clientId: string;
  /** The client secret of the Google apps server. */
  clientSecret?: string | null;
  /** The list of identity provider domains. */
  identityProviderDomains: string[];
}

/** Contract */
export interface Contract {
  /**
   * Unique identifier of the contract
   * This represents the ID value in the Tribe Verkoopcontract Entity
   * @format uuid
   */
  id: string;
  /**
   * The name of the contract
   * This represents the _Name value in the Tribe Entity
   */
  name: string | null;
  /**
   * The domain of the contract
   * This represents the Domein value in the Tribe Entity
   */
  domain: string | null;
  /**
   * The status of the contract
   * This represents the Contractstatus value in the Tribe Entity
   */
  status: string | null;
  /**
   * The starting date of the offer.
   * This represents the Ingangsdatum value in the Tribe Entity
   */
  statingDate?: string | null;
  /**
   * The end date of the offer.
   * This represents the Einddatum value in the Tribe Entity
   */
  endDate?: string | null;
  /**
   * The duration of the contract
   * This represents the Looptijd value in the Tribe Entity
   */
  duration: string | null;
  /**
   * The service level agreement of the contract
   * This represents the Service Level Agreement value in the Tribe Entity
   */
  servicelevelAgreement: ContractDocument | null;
  /**
   * The contract document of the contract
   * This represents the Contractdocument value in the Tribe Entity
   */
  contractDocument: ContractDocument | null;
  /**
   * The processing agreement of the contract
   * This represents the Verwerkersovereenkomst value in the Tribe Entity
   */
  processingAgreement: ContractDocument | null;
}

/** ContractDocument */
export interface ContractDocument {
  /**
   * The name of the contract document
   * This represents the name of the contract document in the Tribe Entity
   */
  name: string | null;
  /**
   * The URL of the contract document
   * This represents the URL of the contract document in the Tribe Entity
   */
  url: string | null;
}

/** EndUserAppOrganizationOut */
export interface EndUserAppOrganizationOut {
  /**
   * Unique identifier of the app organization
   * @format uuid
   */
  id: string;
  /** Description of the app */
  appName: string;
  /** Category of the app */
  category: AppCategories;
  /** Primary hostname of the app */
  primaryHostname?: string | null;
  /** Description of the app instance */
  description: string;
  /** Icon of the app */
  icon: string;
}

/** ExternalAppCreate */
export interface ExternalAppCreate {
  /** Id */
  id: string;
  /** Refresh Token */
  refresh_token: string;
}

/** ExternalAppCredentials */
export interface ExternalAppCredentials {
  /** The id of the credentials, this will be the app name. */
  id: string;
  /** Tribe access_token. */
  access_token: string;
  /** The amount of time it will take before the token becomes invalid. */
  expires_in: number;
  /**
   * The refresh token.
   * The refresh token can be used after the access_token is expired to retrieve a new access_token.
   */
  refresh_token: string;
  /** The user scope. */
  scope: string;
  /** The token type. */
  token_type: string;
}

/** HTTPValidationError */
export interface HTTPValidationError {
  /** Detail */
  detail?: ValidationError[];
}

/** HostAliasCreate */
export interface HostAliasCreate {
  /**
   * The unique identifier of the related app instance.
   * @format uuid
   */
  appInstanceId: string;
  /** Hostname */
  hostname: string;
  /**
   * Start date for this host alias.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this host alias.
   * If this field is not specified, the host alias will not have an end date.
   */
  ended?: string | null;
}

/** HostAliasOut */
export interface HostAliasOut {
  /**
   * Unique identifier of the host alias.
   * @format uuid
   */
  id: string;
  /**
   * The unique identifier of the related app instance.
   * @format uuid
   */
  appInstanceId: string;
  /** Hostname */
  hostname: string;
  /** Primary hostalias indicator */
  primaryHostalias: boolean;
  /**
   * Started
   * @format date-time
   */
  started: string;
  /** Ended */
  ended?: string | null;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
}

/** HostAliasUpdate */
export interface HostAliasUpdate {
  /** Hostname */
  hostname: string;
  /** Primary hostalias indicator */
  primaryHostalias: boolean;
  /**
   * Start date for this host alias.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this host alias.
   * If this field is not specified, the host alias will not have an end date.
   */
  ended?: string | null;
}

/** IdentityApi */
export enum IdentityApi {
  MicrosoftIdentityPlatformV20 = "microsoft-identity-platform-v2.0",
}

/**
 * IntegrationCreate
 * The IntegrationCreate class contains all fields required to create an integration.
 */
export interface IntegrationCreate {
  /**
   * Integration key
   * The key of the organization in the external system denoted by the integration type.
   */
  integrationKey: string;
  /**
   * Integration type
   * The type of the organization integration denoting an external system.
   */
  integrationType: OrganizationIntegrationType;
  /**
   * Unique identifier of the organization to create the integration for.
   * @format uuid
   */
  organizationId: string;
}

/**
 * IntegrationOut
 * The IntegrationOut class contains all fields that are returned by the create and update
 * integration operations.
 */
export interface IntegrationOut {
  /**
   * Integration key
   * The key of the organization in the external system denoted by the integration type.
   */
  integrationKey: string;
  /**
   * Integration type
   * The type of the organization integration denoting an external system.
   */
  integrationType: OrganizationIntegrationType;
  /**
   * Unique identifier of the organization integration
   * @format uuid
   */
  id: string;
  /**
   * Unique identifier of the organization.
   * @format uuid
   */
  organizationId: string;
}

/**
 * IntegrationUpdate
 * The IntegrationUpdate class contains all fields required to update an integration.
 */
export interface IntegrationUpdate {
  /**
   * Integration key
   * The key of the organization in the external system denoted by the integration type.
   */
  integrationKey: string;
  /**
   * Integration type
   * The type of the organization integration denoting an external system.
   */
  integrationType: OrganizationIntegrationType;
}

/** Lead */
export interface Lead {
  /** The name of the applicant. */
  firstName?: string | null;
  /** The name of the applicant. */
  lastName?: string | null;
  /** The organization of the applicant. */
  organization?: string | null;
  /** The lead message. */
  message?: string | null;
  /** Telephone number of the applicant. */
  telephone?: string | null;
  /** Email address of the applicant. */
  email?: string | null;
  /** The uuid of the lead subject. */
  subject?: string | null;
  /** The uuid of the application. */
  application?: string | null;
  /** The uuid of the team. */
  team?: string | null;
  /** Source value for the lead. */
  source?: string | null;
  /** Medium value for the lead. */
  medium?: string | null;
  /** Campaign value for the lead. */
  campaign?: string | null;
}

/** LeadCreate */
export interface LeadCreate {
  /** The name of the applicant. */
  firstName?: string | null;
  /** The name of the applicant. */
  lastName?: string | null;
  /** The organization of the applicant. */
  organization?: string | null;
  /** The lead message. */
  message?: string | null;
  /** Telephone number of the applicant. */
  telephone?: string | null;
  /** Email address of the applicant. */
  email?: string | null;
  /** The uuid of the lead subject. */
  subject?: string | null;
  /** The uuid of the application. */
  application?: string | null;
  /** The uuid of the team. */
  team?: string | null;
  /** Source value for the lead. */
  source?: string | null;
  /** Medium value for the lead. */
  medium?: string | null;
  /** Campaign value for the lead. */
  campaign?: string | null;
}

/** LicenceTypes */
export enum LicenceTypes {
  SiteLicence = "siteLicence",
  Users = "users",
  QuantityScales = "quantityScales",
}

/** LoginInfoConnectionOut */
export interface LoginInfoConnectionOut {
  /** The auth0 identifier of the connection */
  auth0Identifier: string;
  /** The name of the connection */
  name: string;
}

/** LoginInfoOut */
export interface LoginInfoOut {
  /** The auth0 identifier of the organization */
  auth0Identifier: string;
  /**
   * Unique identifier of the organization
   * @format uuid
   */
  organizationId: string;
  /** Name of the organization */
  organizationName: string;
}

/** LoginInfoStylingOut */
export interface LoginInfoStylingOut {
  /** The styling of the organization */
  organizationStyling?: OrganizationStyling | null;
  /** The styling of the app */
  appStyling?: AppStyling | null;
}

/** MemberCreate */
export interface MemberCreate {
  /** Member name */
  name: string;
  /** Member e-mail */
  email: string;
  /** The id of the provider (organization/auth) the connection belongs to. Can only be specified with admin permissions, otherwise ignored and the connection is assumed to belong to the organization of the current user. */
  organizationAuthId?: string | null;
  /** Auth0 id of the connection */
  connectionId: string;
}

/** MemberOut */
export interface MemberOut {
  /** Member name */
  name: string;
  /** Member e-mail */
  email: string;
  /** Member id */
  id: string;
  /**
   * Member picture URL
   * @format uri
   * @minLength 1
   * @maxLength 2083
   */
  picture: string;
}

/** MemberUpdate */
export interface MemberUpdate {
  /** Member name */
  name: string;
  /** Member e-mail */
  email: string;
  /** Member password, if absent the password is unchanged. */
  password?: string | null;
}

/** Offer */
export interface Offer {
  /**
   * Unique identifier of the offer
   * This represents the ID value in the Tribe Activiteit Entity
   * @format uuid
   */
  id: string;
  /**
   * Readable identification of the offer
   * This represents the Number value in the Tribe Activiteit Entity
   */
  number: string | null;
  /**
   * The subject of the offer
   * This represents the Subject value in the Tribe Activiteit Entity
   */
  subject: string | null;
  /**
   * The link to sign the offer.
   * This represents the SignLink value in the Tribe Activity_Offer Entity
   */
  signLink: string | null;
  /**
   * Indicator if the offer is closed.
   * This represents the IsClosed value in the Tribe Entiteit Entity
   */
  isClosed?: boolean | null;
}

/** OperatorGroup */
export interface OperatorGroup {
  /**
   * Unique identifier of the operator group
   * This represents the id value in the TopDesk operator group
   * @format uuid
   */
  id: string;
  /**
   * The status of the operator group
   * This represents the status value in the TopDesk operator group
   */
  status: string | null;
  /**
   * The name of the operator group
   * This represents the groupName value in the TopDesk operator group
   */
  groupName: string | null;
  /**
   * The branch of the operator group
   * This represents the branch value in the TopDesk operator group
   */
  branch: Branch | null;
}

/** OrganizationAuthCreate */
export interface OrganizationAuthCreate {
  /**
   * Unique identifier of the organization
   * @format uuid
   */
  organizationId: string;
  /**
   * The authentication provider
   * @default "auth0"
   */
  provider?: Provider;
}

/** OrganizationAuthOut */
export interface OrganizationAuthOut {
  /**
   * Unique identifier of the organization
   * @format uuid
   */
  organizationId: string;
  /**
   * The authentication provider
   * @default "auth0"
   */
  provider?: Provider;
  /**
   * Unique identifier of the organization auth
   * @format uuid
   */
  id: string;
  /** The auth0 identifier of the organization */
  auth0Identifier: string;
}

/** OrganizationCreate */
export interface OrganizationCreate {
  /** Organization name */
  name: string;
  /**
   * The primary color of the organization
   * A hexadecimal color
   */
  primaryColor?: string | null;
  /**
   * The secondary color of the organization
   * A hexadecimal color
   */
  secondaryColor?: string | null;
  /** The URL of the logo of the organization */
  logoUrl?: string | null;
  /**
   * Start date for this organization.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this organization.
   * If this field is not specified, the organization will not have an end date.
   */
  ended?: string | null;
  /**
   * Flag indicating whether the organization is a partnership of other organizations
   * @default false
   */
  isPartnership?: boolean | null;
}

/** OrganizationCreateManyArray */
export interface OrganizationCreateManyArray {
  /** A collection of organizations to import. */
  data: OrganizationCreateManyItem[];
}

/** OrganizationCreateManyArrayOut */
export interface OrganizationCreateManyArrayOut {
  /** The import result for a collection of organizations. */
  data: OrganizationCreateManyItemOut[];
}

/**
 * OrganizationCreateManyItem
 * This type is specific for importing organizations that originate from another system, like
 * exported from a CRM system.
 */
export interface OrganizationCreateManyItem {
  /**
   * Integration key
   * The key of the organization in the external system denoted by the integration type.
   */
  integrationKey: string;
  /**
   * Integration type
   * The type of the organization integration denoting an external system.
   */
  integrationType: OrganizationIntegrationType;
  /** Organization name */
  name: string;
  /**
   * The primary color of the organization
   * A hexadecimal color
   */
  primaryColor?: string | null;
  /**
   * The secondary color of the organization
   * A hexadecimal color
   */
  secondaryColor?: string | null;
  /** The URL of the logo of the organization */
  logoUrl?: string | null;
  /**
   * Start date for this organization.
   * This date will be set to the current date and time ('now') if not specified.
   */
  started?: string | null;
  /**
   * End date for this organization.
   * If this field is not specified, the organization will not have an end date.
   */
  ended?: string | null;
  /**
   * Flag indicating whether the organization is a partnership of other organizations
   * @default false
   */
  isPartnership?: boolean | null;
}

/** OrganizationCreateManyItemOut */
export interface OrganizationCreateManyItemOut {
  /**
   * Integration key
   * The key of the organization in the external system denoted by the integration type.
   */
  integrationKey: string;
  /**
   * Integration type
   * The type of the organization integration denoting an external system.
   */
  integrationType: OrganizationIntegrationType;
  /** The result. */
  result: string;
}

/** OrganizationIntegrationType */
export enum OrganizationIntegrationType {
  Crm = "crm",
  Plesk = "plesk",
  Topdesk = "topdesk",
}

/** OrganizationOut */
export interface OrganizationOut {
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the organization
   * @format uuid
   */
  id: string;
  /** Organization name */
  name: string;
  /**
   * The primary color of the organization
   * A hexadecimal color
   */
  primaryColor?: string | null;
  /**
   * The secondary color of the organization
   * A hexadecimal color
   */
  secondaryColor?: string | null;
  /** The URL of the logo of the organization */
  logoUrl?: string | null;
  /**
   * Started
   * @format date-time
   */
  started: string;
  /** Ended */
  ended?: string | null;
  /**
   * Flag indicating whether the organization is a partnership of other organizations
   * @default false
   */
  isPartnership?: boolean | null;
}

/** OrganizationStyling */
export interface OrganizationStyling {
  /** The name of the organization */
  name: string;
  /** The primary color of the organization */
  primaryColor?: string | null;
  /** The secondary color of the organization */
  secondaryColor?: string | null;
  /** The URL of the logo of the organization */
  logoUrl?: string | null;
}

/** OrganizationSummary */
export interface OrganizationSummary {
  /**
   * Unique identifier of the organization
   * @format uuid
   */
  id: string;
  /** Organization name */
  name: string;
}

/** OrganizationUpdate */
export interface OrganizationUpdate {
  /** New name for this organization */
  name: string;
  /**
   * New start date for this organization
   * @format date-time
   */
  started: string;
  /** New end date for this organization */
  ended?: string | null;
  /** New deleted date for this organization */
  deleted?: string | null;
  /**
   * The new primary color of the organization
   * A hexadecimal color
   */
  primaryColor?: string | null;
  /**
   * The new secondary color of the organization
   * A hexadecimal color
   */
  secondaryColor?: string | null;
  /** The new URL of the logo of the organization */
  logoUrl?: string | null;
  /**
   * Flag indicating whether the organization is a partnership of other organizations
   * @default false
   */
  isPartnership?: boolean | null;
}

/** Provider */
export enum Provider {
  Auth0 = "auth0",
}

/** ProvisionerAcquireLock */
export interface ProvisionerAcquireLock {
  /**
   * Unique identifier of the provisioner acquireing the lock.
   * @format uuid
   */
  stateLockProvisionerId: string;
  /**
   * Datetime until the provisioner expects requiering the lock.
   * The given value must be higher than the current date time on the server. Also, the given value must not be futher than one day in the future, so a provisioner cannot claim an app instance for longer than a day.
   * @format date-time
   */
  stateLockUntil: string;
  /**
   * Unique identifier of the app instance.
   * @format uuid
   */
  appInstanceId: string;
}

/** ProvisionerAcquireLockOut */
export interface ProvisionerAcquireLockOut {
  /**
   * Unique identifier of the provisioner acquireing the lock.
   * @format uuid
   */
  stateLockProvisionerId: string;
  /**
   * Datetime until the provisioner expects requiering the lock.
   * The given value must be higher than the current date time on the server. Also, the given value must not be futher than one day in the future, so a provisioner cannot claim an app instance for longer than a day.
   * @format date-time
   */
  stateLockUntil: string;
  /**
   * Unique identifier of the app instance.
   * @format uuid
   */
  appInstanceId: string;
  /**
   * Datetime of the most recent update of the app instance definition in the database.
   * @format date-time
   */
  lastModified: string;
}

/** ProvisionerInstanceOut */
export interface ProvisionerInstanceOut {
  /**
   * Unique identifier of the app instance
   * @format uuid
   */
  appInstanceId: string;
  /** App instance description */
  description: string;
  /** List of tags that can be used to identify or select an AppInstance. */
  systemTags?: string[] | null;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
  app: AppOut;
  organization?: OrganizationOut | null;
  configuration: AppInstanceConfigurationOut;
  /** Hostaliases */
  hostAliases?: HostAliasOut[] | null;
}

/** ProvisionerReleaseLock */
export interface ProvisionerReleaseLock {
  /**
   * Unique identifier of the provisioner releasing the lock.
   * @format uuid
   */
  stateLockProvisionerId: string;
  /**
   * Datetime of the most recent update of the provisioned app instance.
   * @format date-time
   */
  stateLastUpdated: string;
  /**
   * Datetime of the most recent update of the app instance definition in the database.
   * @format date-time
   */
  lastModified: string;
  /**
   * Unique identifier of the app instance.
   * @format uuid
   */
  appInstanceId: string;
  /** The status of the app instance. */
  appInstanceStatus: AppInstanceStatus;
  /** The log of the Ansible run of the app instance. */
  appInstanceLog: string;
}

/** Reaction */
export interface Reaction {
  /**
   * Unique identifier of the reaction
   * This represents the id value in the TopDesk incident
   * @format uuid
   */
  id: string;
  /**
   * Readable memoText of the reaction
   * This represents the plainText value in the TopDesk progresstrail
   */
  memoText: string | null;
  /**
   * The request person of the reaction
   * This represents the person.name value in the TopDesk progresstrail
   */
  person: string | null;
  /**
   * The request operator of the reaction
   * This represents the operator.name value in the TopDesk progresstrail
   */
  operator: string | null;
  /**
   * The request fileName of the reaction
   * This represents the fileName value in the TopDesk progresstrail
   */
  fileName: string | null;
  /**
   * The request downloadUrl of the reaction
   * This represents the downloadUrl value in the TopDesk progresstrail
   */
  downloadUrl: string | null;
  /**
   * The entry date of the ticket.
   * This represents the entryDate value in the TopDesk progresstrail
   */
  entryDate: string | null;
}

/** SearchResult */
export interface SearchResult {
  /** Result */
  result?: any;
  /**
   * Start
   * @min 0
   */
  start: number;
  /** End */
  end?: number | null;
  /**
   * Total
   * @min 0
   */
  total: number;
}

/** StatsOut */
export interface StatsOut {
  /** App name */
  activeInstances: number;
  /** App description */
  newInstances: number;
  /** App icon */
  activeOrganizations: number;
  /** Unique identifier of the requestor */
  id: string;
}

/** Strategy */
export enum Strategy {
  Adfs = "adfs",
  Auth0 = "auth0",
  Googleapps = "googleapps",
  Waad = "waad",
}

/** SubCategory */
export interface SubCategory {
  /**
   * Unique identifier of the subcategory
   * This represents the id value in the TopDesk incident subcategory
   * @format uuid
   */
  id: string;
  /**
   * The name of the subcategory
   * This represents the name value in the TopDesk incident subcategory
   */
  name: string | null;
  /**
   * The category of the subcategory
   * This represents the category value in the TopDesk incident subcategory
   */
  category: Category | null;
}

/** Ticket */
export interface Ticket {
  /**
   * Unique identifier of the ticket
   * This represents the id value in the TopDesk incident
   * @format uuid
   */
  id: string;
  /**
   * Readable identification of the ticket
   * This represents the number value in the TopDesk incident
   */
  number: string | null;
  /**
   * The request callerName of the ticket
   * This represents the caller.dynamicName value in the TopDesk incident
   */
  callerName: string | null;
  /**
   * The request description of the ticket
   * This represents the request value in the TopDesk incident
   */
  request: string | null;
  /**
   * The briefDescription of the ticket.
   * This represents the briefDescription value in the TopDesk incidentEntity
   */
  briefDescription: string | null;
  /**
   * The status for the ticket.
   * This represents the processingStatus.name value in the TopDesk incident
   */
  status: string | null;
  /**
   * The type for the ticket.
   * This represents the callType.name value in the TopDesk incident
   */
  type: string | null;
  /**
   * The category for the ticket.
   * This represents the category.name value in the TopDesk incident
   */
  category: string | null;
  /**
   * The subCategory for the ticket.
   * This represents the subcategory.name value in the TopDesk incident
   */
  subCategory: string | null;
  /**
   * The closed value of the ticket.
   * This represents the closed value in the TopDesk incident
   */
  closed: boolean | null;
  /**
   * The creating date of the ticket.
   * This represents the creationDate value in the TopDesk incident
   */
  creationDate: string | null;
  /**
   * The closing date of the ticket.
   * This represents the closedDate value in the TopDesk incident
   */
  closedDate: string | null;
  /**
   * The target date of the ticket.
   * This represents the targetDate value in the TopDesk incident
   */
  targetDate: string | null;
  /**
   * The reactions of the ticket.
   * This represents the progresstrail value in the TopDesk incident
   */
  reactions: Reaction[];
}

/** TicketConfigurationCreate */
export interface TicketConfigurationCreate {
  /** Hostname */
  description: string;
  /**
   * The unique identifier of the TopDesk category.
   * @format uuid
   */
  category_id: string;
  /** The unique identifier of the TopDesk sub category. */
  sub_category_id?: string | null;
  /**
   * The unique identifier of the TopDesk operator group.
   * @format uuid
   */
  operator_group_id: string;
  /** The type of ticket. */
  ticket_type: string;
  /** List of questons for the TicketConfiguration. */
  questions?: Record<string, string>[] | null;
  /** List of tags that can be used to identify an TicketConfiguration. */
  tags?: string[] | null;
}

/** TicketConfigurationOut */
export interface TicketConfigurationOut {
  /**
   * Unique identifier of the ticket configuration.
   * @format uuid
   */
  id: string;
  /** Description */
  description: string;
  /** Category Id */
  category_id: string;
  /** Sub Category Id */
  sub_category_id?: string | null;
  /** Operator Group Id */
  operator_group_id: string;
  /** Ticket Type */
  ticket_type: string;
  /** List of questons for the TicketConfiguration. */
  questions?: Record<string, string>[] | null;
  /** List of tags that can be used to identify an TicketConfiguration. */
  tags?: string[] | null;
  /**
   * Created
   * @format date-time
   */
  created: string;
  /**
   * Lastmodified
   * @format date-time
   */
  lastModified: string;
}

/** TicketConfigurationUpdate */
export interface TicketConfigurationUpdate {
  /**
   * Unique identifier of the ticket configuration.
   * @format uuid
   */
  id: string;
  /** Description */
  description: string;
  /** Category Id */
  category_id: string;
  /** Sub Category Id */
  sub_category_id?: string | null;
  /** Operator Group Id */
  operator_group_id: string;
  /** Ticket Type */
  ticket_type: string;
  /** List of questons for the TicketConfiguration. */
  questions?: Record<string, string>[] | null;
  /** List of tags that can be used to identify an TicketConfiguration. */
  tags?: string[] | null;
}

/** TicketCreate */
export interface TicketCreate {
  /**
   * The entry type name for the ticket.
   * This represents the entryType.name value in the TopDesk incident
   */
  entryTypeName: string;
  /**
   * The category id for the ticket.
   * This represents the category.id value in the TopDesk incident
   */
  categoryId: string;
  /**
   * The sub category id for the ticket.
   * This represents the subcategory.id value in the TopDesk incident
   */
  subCategoryId?: string | null;
  /**
   * The call type name for the ticket.
   * This represents the callType.name value in the TopDesk incident
   */
  callTypeName: string;
  /**
   * The briefDescription of the ticket.
   * This represents the briefDescription value in the TopDesk incidentEntity
   */
  briefDescription: string;
  /**
   * The request description of the ticket
   * This represents the request value in the TopDesk incident
   */
  request: string;
  /**
   * The operatorGroup of the ticket
   * This represents the operatorGroup id value in the TopDesk incident
   */
  operatorGroup: string;
}

/** TicketUpdate */
export interface TicketUpdate {
  /**
   * Readable identification of the ticket
   * This represents the number value in the TopDesk incident
   */
  number: string | null;
  /**
   * The request callerName of the ticket
   * This represents the caller.dynamicName value in the TopDesk incident
   */
  callerName: string | null;
  /**
   * The request description of the ticket
   * This represents the request value in the TopDesk incident
   */
  request: string | null;
  /**
   * The briefDescription of the ticket.
   * This represents the briefDescription value in the TopDesk incidentEntity
   */
  briefDescription: string | null;
  /**
   * The status for the ticket.
   * This represents the processingStatus.name value in the TopDesk incident
   */
  status: string | null;
  /**
   * The type for the ticket.
   * This represents the callType.name value in the TopDesk incident
   */
  type: string | null;
  /**
   * The category for the ticket.
   * This represents the category.name value in the TopDesk incident
   */
  category: string | null;
  /**
   * The subCategory for the ticket.
   * This represents the subcategory.name value in the TopDesk incident
   */
  subCategory: string | null;
  /**
   * The closed value of the ticket.
   * This represents the closed value in the TopDesk incident
   */
  closed: boolean | null;
  /**
   * The creating date of the ticket.
   * This represents the creationDate value in the TopDesk incident
   */
  creationDate: string | null;
  /**
   * The closing date of the ticket.
   * This represents the closedDate value in the TopDesk incident
   */
  closedDate: string | null;
  /**
   * The target date of the ticket.
   * This represents the targetDate value in the TopDesk incident
   */
  targetDate: string | null;
  /**
   * The reactions of the ticket.
   * This represents the progresstrail value in the TopDesk incident
   */
  reactions: Reaction[];
  /**
   * The action for the ticket.
   * This will be added as progresstrail item in the TopDesk incident
   */
  action?: string | null;
}

/** ValidationError */
export interface ValidationError {
  /** Location */
  loc: (string | number)[];
  /** Message */
  msg: string;
  /** Error Type */
  type: string;
}
