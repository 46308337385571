/* eslint-disable */
import { AuthConnectionOut } from '../types/ecoRequests';


interface Options {
  adfs_server: string;
  mfaEnabled: boolean;
  domain: string;
  applicationId: string;
  secretValue: string;
  authorizationUrl: string;
  clientId: string;
  clientSecret: string;
  tokenUrl: string;
  identityProviderDomains: string;
}

export const connectionsTransform = (data: AuthConnectionOut): ReturnType => {
  const { strategy, name, hostnames, organizationAuthId, options, ...restData } = data
  let returnObject = {}

  if (strategy === 'adfs') {
    const { adfs_server } = data.options as unknown as Options

    returnObject = {
      organizationAuthId,
      [String(strategy)]: {
        name,
        hostnames,
        options: {
          adfs_server
        },
      }
    }
  } else if (strategy === 'auth0') {
    const { mfaEnabled } = data.options as unknown as Options

    returnObject = {
      organizationAuthId,
      [String(strategy)]: {
        name,
        hostnames,
        options: {
          mfaEnabled
        },
      }
    }
  } else if (strategy === 'waad') {
    const { domain, applicationId, secretValue } = data.options as unknown as Options

    returnObject = {
      organizationAuthId,
      [String(strategy)]: {
        name,
        hostnames,
        options: {
          domain,
          applicationId,
          secretValue,
          identityApi: 'microsoft-identity-platform-v2.0'
        },
      }
    }
  } else if (strategy === 'googleapps') {
    const { domain, clientId, clientSecret, identityProviderDomains } = data.options as unknown as Options

    returnObject = {
      organizationAuthId,
      [String(strategy)]: {
        name,
        hostnames,
        options: {
          domain,
          clientId,
          clientSecret,
          identityProviderDomains
        },
      }
    }
  } else {
    returnObject = {
      organizationAuthId,
      [String(strategy)]: {
        name,
        hostnames,
        options
      }
    }
  }

  return returnObject
}

interface ReturnType {
  [key: string]: any;
}
